<template>
  <div class="container">
    <content-title :nav="nav"></content-title>

    <div class="contentList">
      <el-form :inline="true" class="demo-form-inline">
        <el-form-item label="团长名称">
          <el-input
            type="text"
            clearable
            v-model="name"
            name="mechanism_name"
            id="name"
          />
        </el-form-item>
        <el-form-item label="团长手机号">
          <el-input
            type="text"
            clearable
            v-model="phone"
            name="mechanism_telephone"
            id="tell"
          />
        </el-form-item>
        <el-form-item label="状态">
          <el-select v-model="status">
            <el-option label="正在审核" value="1"></el-option>
            <el-option label="审核通过" value="2"></el-option>
            <el-option label="审核拒绝" value="3"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button @click="search()" type="primary">查询</el-button>
        </el-form-item>
      </el-form>
      <div class="paging">
        <el-table
          :header-cell-style="{ background: '#EAEAEA', color: '#434343' }"
          :data="recording"
          tooltip-effect="dark"
          style="width: 100%; margin-top: 20px"
          highlight-current-row
          v-loading="is_loading"
        >
          <el-table-column
            prop="id"
            align="center"
            label="id"
          ></el-table-column>
          <el-table-column
            prop="name"
            align="center"
            label="团长名称"
          ></el-table-column>
          <el-table-column
            prop="phone"
            align="center"
            label="团长电话"
          ></el-table-column>
          <el-table-column
            prop="area"
            align="center"
            label="社区"
          ></el-table-column>
          <el-table-column
          width="200"
            prop="create_time"
            align="center"
            label="申请时间"
          ></el-table-column>
          <el-table-column prop="status" label="状态" align="center">
            <template slot-scope="scope">
              <span v-if="scope.row.status == 1" style="color: #b8b8b8"
                >正在审核</span
              >
              <span v-else-if="scope.row.status == 2" style="color: #23c3c4"
                >审核通过</span
              >
              <span v-else-if="scope.row.status == 3" style="color: #ff6262"
                >审核拒绝</span
              >
            </template>
          </el-table-column>
          <el-table-column
            prop="is_recommend"
            align="left"
            label="操作"
            width="300"
          >
            <template slot-scope="scope">
              <div style="display:flex;flex-wrap:wrap">
                <el-button @click="toDetails(scope.row)" size="small" type="primary" v-if="scope.row.status != 1">查看详情</el-button>
                <el-button @click="pass(scope.row,1)" size="small" type="success" v-if="scope.row.status != 1">反审核</el-button>
                <el-button @click="pass(scope.row, 2)" size="small" type="success" v-if="scope.row.status == 1">通过
                </el-button>
                <el-button
                  @click="pass(scope.row, 3)"
                  size="small"
                  type="danger"
                  v-if="scope.row.status == 1"
                  >拒绝
                </el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页 -->
        <paging-fy
          @currentPageChange="handleCurrentChange"
          :currentPage="currentPage"
          :total="total"
        ></paging-fy>
      </div>
    </div>
    <el-dialog title="提示" :visible.sync="dialogVisible" width="30%">
      <el-input v-model="context" placeholder="请输入拒绝理由"></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      nav: {
        firstNav: "团长管理",
        secondNav: "团长列表",
      },
      name: "",
      status: "",
      phone: "",
      recording: [],
      currentPage: 1,
      total: 0,
      context: "",
      currefues: {},
      is_loading: true,
      dialogVisible: false,
      issearch: false,
    };
  },
  created() {
    this.GetList();
  },
  methods: {
    toDetails(row){
      this.$router.push({
        name: "userDetails",
        params: {
          id: row.user_id,
        },
      });
    },
    GetList() {
      this.is_loading = true;
      let url = "user/userRecommender/queryManagerListPage";
      this.$axios({
        url,
        params: {
          pageSize: 10,
          currentPage: this.currentPage,
          type: "community_group",
        },
      })
        .then((res) => {
          // console.log(res.data);
          this.recording = res.data.data.rows;
          this.total = res.data.data.total;
          this.is_loading = false;
        })
        .catch((rej) => {
          this.is_loading = false;
        });
    },
    search() {
      this.is_loading = true;
      this.issearch = true;
      let url = "user/userRecommender/queryByMessage";
      let params = {
        pageSize: 10,
        currentPage: this.currentPage,
        type: "community_group",
      };
      if (this.name != "") {
        params.name = this.name;
      }
      if (this.phone != "") {
        params.phone = this.phone;
      }
      if (this.status != "") {
        params.status = this.status;
      }
      this.$axios({
        url,
        params,
        method: "get",
      })
        .then((res) => {
          // console.log(res.data);
          this.recording = res.data.data.rows;
          this.total = res.data.data.total;
          this.is_loading = false;
        })
        .catch((rej) => {
          this.is_loading = false;
        });
    },
    pass(row, status) {
      let url = "user/userRecommender/recommenderAudit";
      this.$axios
        .post(url, {
          status,
          id: row.id,
        })
        .then((res) => {
          if (res.data.code == 0) {
            this.$message({ type: "info", message: "审核成功" });
            this.GetList();
          } else {
            this.$message({ type: "info", message: res.data.message });
          }
        });
    },

    handleCurrentChange(v) {
      this.currentPage = v;
      if (this.issearch) {
        this.search();
      } else {
        this.GetList();
      }
    },
  },
};
</script>
<style></style>
